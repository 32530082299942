import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import thousandSeparator from "../../utils/thousandSeparator";
import Page from "../../components/Layout/Page";
import axios from "../../utils/axios";
import "./TbkFinal.css";

function TbkFinalWebPay() {
  const history = useHistory();
  const customer = useSelector((state) => state.account.user);
  const [tbkPayment, setTbkPayment] = useState(null);

  useEffect(() => {
    const getLastPayment = async () => {
      const paymentResponse = await axios.get(
        `/api/tbkpayments/last/${customer.id}`
      );

      setTbkPayment(paymentResponse.data.data.tbkPayment);
    };

    getLastPayment();
  }, [customer]);

  return (
    <Page title="Club Líquidos | TBK Final">
      <div className="tbk-final-container">
        <div className="tbk-final-info">
          <img src="/img/green_check.png" alt="success" />
          <h1>Abono completado con éxito</h1>
          {tbkPayment && (
            <div className="tbk-payment-info">
              <ul>
                <li>
                  <p>
                    <b>Monto:</b> ${thousandSeparator(tbkPayment.amount, ".")}
                  </p>
                </li>
                <li>
                  <p>
                    <b>Medio de pago:</b> Webpay Plus
                  </p>
                </li>
                <li>
                  <p>
                    <b>Tarjeta:</b> **** **** **** {tbkPayment.card_number}
                  </p>
                </li>
              </ul>
            </div>
          )}
          <button
            className="yellow-button"
            type="button"
            onClick={() => history.push("/billetera/info&simple")}
          >
            IR A BILLETERA
          </button>
        </div>
      </div>
    </Page>
  );
}

export default TbkFinalWebPay;
