export const SAVE_KHIPU_ID = "@account/khipu-save";
export const CLEAR_KHIPU_ID = "@account/khipu-clear";

export function saveKhipuId(id) {
  return async (dispatch) => {
    dispatch({
      type: SAVE_KHIPU_ID,
      payload: {
        id,
      },
    });
  };
}

export function clearKhipuId() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_KHIPU_ID,
    });
  };
}
