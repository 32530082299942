import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import "./EditPassword.css";
import { useSelector } from "react-redux";


function EditPassword({ setEditPassword }) {
  const customer = useSelector((state) => state.account.user);
  const [inputValues, setInputValues] = useState({
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
    phone: customer.phone_number,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [waitConfirmation, setWaitConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [codeId, setCodeId] = useState(null);
  const [coolDown, setCoolDown] = useState(0);

  useEffect(() => {
    const decreaseCoolDown = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (coolDown > 0) {
        setCoolDown(coolDown - 1);
      }
    };

    decreaseCoolDown();
  }, [coolDown, setCoolDown]);

  const handleChange = (event) => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const getInputName = (key) => {
    switch (key) {
      case "oldPassword":
        return "contraseña actual";
      case "password":
        return "contraseña";
      case "passwordConfirmation":
          return "confirmación contrasena"
      default:
        return "requerido";
    }
  };

  const sendCode = async () => {
    setLoading(true);
    setCoolDown(60);
    const axiosResponse = await axios.post("/api/customers/register-sms", {
      phone: inputValues.phone,
    });
    if (axiosResponse.data.code !== 200) {
      setError(axiosResponse.data.data.errorMessage);
      setLoading(false);
      return;
    } else {
      setCodeId(axiosResponse.data.data.codeId);
      setLoading(false);
    }
  };

  const submitForm = async () => {
    setError(null);
    setLoading(true);

    for (const key in inputValues) {
        if (
          !inputValues[key]) {
          const inputName = getInputName(key);
          setError(`Falta llenar campo "${inputName}"`);
          setLoading(false);
          return;
        }
      }

    if(!inputValues.oldPassword){
        setError("Debe ingresar contraseña actual");
        setLoading(false);
        return;
    }

    if (!inputValues.password) {
      setError("Debe ingresar nueva contraseña");
      setLoading(false);
      return;
    }

    if(!inputValues.passwordConfirmation){
      setError("Debe ingresar confirmación de la nueva contraseña");
      setLoading(false);
      return;
    }

    if (
      inputValues.oldPassword ||
      inputValues.password ||
      inputValues.passwordConfirmation
    ) {
      if (inputValues.password !== inputValues.passwordConfirmation) {
        setError("Contraseñas no coinciden");
        setLoading(false);
        return;
      }

      if (inputValues.password.length < 8) {
        setError("Contraseña debe tener largo de al menos 8");
        setLoading(false);
        return;
      }
    }

    sendCode();
    setLoading(false);
    setWaitConfirmation(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      submitForm();
    }
  };

  const handleRegisterCode = async () => {
    setLoading(true);
    let data = {};
    Object.assign(data, inputValues);
    data.confirmationCode = confirmationCode;
    data.codeId = codeId;
    const axiosResponse = await axios.put(
      `/api/customers/${customer.id}/change-password`,
      data
    );

    if (axiosResponse.data.code !== 200) {
      setError(axiosResponse.data.data.errorMessage);
      setLoading(false);
    } else {
      window.location.href = "/perfil";
      setLoading(false);
    }
  };

  const handleConfirmKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRegisterCode();
    }
  };

  const confirmationBox = () => (
    <div className="confirmation-box">
      <div>
        <h4 className="text-code-explanation">
          Te enviamos un sms con el código al teléfono que ingresaste!
        </h4>
        <input
          type="text"
          name="confirmationCode"
          value={inputValues.confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          onKeyDown={handleConfirmKeyDown}
        />
        <button
          type="button"
          className="confirm-code"
          onClick={handleRegisterCode}
          disabled={loading}
        >
          Confirmar
        </button>
        <button
          disabled={coolDown > 0}
          type="button"
          className={`send-new-code ${coolDown > 0 && "disabled-code"}`}
          onClick={sendCode}
        >
          Enviar código de nuevo
        </button>
        <p className="edit-cooldown-message">
          {coolDown > 0 && `Podrá enviar otro código en ${coolDown}`}
        </p>
        <p className="error-message">{error}</p>
      </div>
    </div>
  );

  return (
    <div>
      {!waitConfirmation ? (
        <div className="edit-customer-container">
          <div className="edit-customer-box">
            <h1>Cambiar contraseña</h1>
            <div className="edit-customer-form password">
              <input
                type="password"
                name="oldPassword"
                value={inputValues.oldPassword}
                onChange={(e) => handleChange(e)}
                placeholder="Contraseña actual"
                onKeyDown={handleKeyDown}
              />
              <input
                type="password"
                name="password"
                value={inputValues.password}
                onChange={(e) => handleChange(e)}
                placeholder="Contraseña"
                onKeyDown={handleKeyDown}
              />
              <input
                type="password"
                name="passwordConfirmation"
                value={inputValues.passwordConfirmation}
                onChange={(e) => handleChange(e)}
                placeholder="Confirmar Contraseña"
                onKeyDown={handleKeyDown}
              />
            </div>
            <p className="error-message">{error}</p>
            <button
              className="edit-customer-button password"
              type="button"
              onClick={() => submitForm()}
              disabled={loading}
            >
              Guardar Cambios
            </button>
            <button
              className="back-profile-button"
              type="button"
              onClick={() => setEditPassword(false)}
            >
              Volver a Perfil
            </button>
          </div>
        </div>
      ) : (
        confirmationBox()
      )}
    </div>
  );
}

export default EditPassword;
