import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import Page from "../../components/Layout/Page";
import "./Home.css";

const Home = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const openYoutube = (link) => {
    setOpen(true);
    setVideoLink(link);
  };

  return (
    <Page title="Club Líquidos | Billetera" current="home">
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div>
          <ReactPlayer url={videoLink} className="youtube-popover" />
        </div>
      </Popover>
      <div className="home-container">
        <div className="welcome-container">
          <div className="welcome-title">
            <img
              className="welcome-youtube-icon"
              src="/img/youtube.png"
              alt="youtube"
              onClick={() => openYoutube("https://youtu.be/CzfcFfSiHp0")}
            />
            <h1>BIENVENIDO AL</h1>
            <img
              className="welcome-logo"
              src="/img/clubliquidos.png"
              alt="logo"
            />
          </div>
          <div className="welcome-buttons">
            <button type="button" onClick={() => history.push("/saber-mas")}>
              QUIERO SABER MÁS
            </button>
            <button type="button" onClick={() => history.push("/registrate")}>
              INSCRÍBETE
            </button>
          </div>
        </div>
        <div className="welcome-side-container">
          <div
            className="welcome-news"
            onClick={() => history.push("/promociones")}
          >
            <h1>[</h1>
            <h2>Promociones</h2>
            <h1>]</h1>
          </div>
          <div
            className="welcome-wallet"
            onClick={() => history.push("/billetera/info&simple")}
          >
            <img src="/img/wallet.png" alt="wallet" />
            <h1>[Mi Billetera]</h1>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Home;
