import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions/accountActions";
import axios from "../../utils/axios";
import thousandSeparator from "../../utils/thousandSeparator";
import "./WalletInfo.css";

function WalletInfo({ setPay, paymentMessage, pay }) {
  const customer = useSelector((state) => state.account.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [points, setPoints] = useState(0);
  const [cash, setCash] = useState(0);
  const [reserved, setReserved] = useState(0);

  useEffect(() => {
    const getCustomerInfo = async () => {
      if (customer) {
        const customerResponse = await axios.get(
          `/api/customers/points/${customer.id}`
        );

        if (customerResponse.data.code === 200) {
          setPoints(customerResponse.data.data.points);
          setCash(customerResponse.data.data.cash);
          setReserved(customerResponse.data.data.reserved);
        } else {
          dispatch(logout());
        }
      } else {
        dispatch(logout());
      }
    };

    getCustomerInfo();
  }, [customer, dispatch, pay]);

  const desktopWallet = () => (
    <div className="wallet-box">
      <div className="wallet-title-container">
        <div className="wallet-title">
          <img src="/img/wallet.png" alt="wallet" />
          <h1>Billetera</h1>
        </div>
        <div className="wallet-title-border-cut" />
      </div>
      <div className="wallet-rounded" />
      <div className="wallet-action-container">
        <div className="wallet-info">
          <div className="wallet-info-row">
            <h2>Compras</h2>
            <h2>${thousandSeparator(points, ".")}</h2>
          </div>
          <div className="wallet-info-row">
            <h2>Abonos</h2>
            <h2>${thousandSeparator(cash, ".")}</h2>
          </div>
          <div className="wallet-info-row">
            <h2>Reservados</h2>
            <h2 style={{ color: "red" }}>
              - ${thousandSeparator(reserved, ".")}
            </h2>
          </div>
          <div className="wallet-info-row">
            <h2>Total Liquipuntos</h2>
            <h2>${thousandSeparator(points + cash - reserved, ".")}</h2>
          </div>
        </div>
        <div className="wallet-buttons">
          <button
            className="yellow-button"
            type="button"
            onClick={() => setPay(true)}
          >
            ABONAR
          </button>
          <button
            className="yellow-button"
            type="button"
            onClick={() => (window.location.href = "https://www.liquidos.cl")}
          >
            COMPRAR CON LIQUIPUNTOS
          </button>
          <button
            className="yellow-button"
            type="button"
            onClick={() => history.push("/promociones")}
          >
            MIS PROMOCIONES
          </button>
        </div>
      </div>
      <div className="mobile-wallet-title">
        <h1>Billetera</h1>
      </div>
      <div className="wallet-dots">
        <div className="wallet-single-dot" />
        <div className="wallet-single-dot" />
        <div className="wallet-single-dot" />
      </div>
    </div>
  );

  const mobileWallet = () => (
    <div className="mobile-wallet-action-container">
      <div className="mobile-wallet-info">
        <div className="mobile-wallet-info-row">
          <h2>Compras</h2>
          <h2>${thousandSeparator(points, ".")}</h2>
        </div>
        <div className="mobile-wallet-info-row">
          <h2>Abonos</h2>
          <h2>${thousandSeparator(cash, ".")}</h2>
        </div>
        <div className="mobile-wallet-info-row">
          <h2>Reservados</h2>
          <h2 style={{ color: "red" }}>
            - ${thousandSeparator(reserved, ".")}
          </h2>
        </div>
        <div className="mobile-wallet-info-row">
          <h2>Total Liquipuntos</h2>
          <h2>${thousandSeparator(points + cash - reserved, ".")}</h2>
        </div>
      </div>
      <div className="mobile-wallet-buttons">
        <button
          className="yellow-button"
          type="button"
          onClick={() => setPay(true)}
        >
          ABONAR
        </button>
        <button
          className="yellow-button"
          type="button"
          onClick={() => (window.location.href = "https://www.liquidos.cl")}
        >
          COMPRAR CON LIQUIPUNTOS
        </button>
        <button
          className="yellow-button"
          type="button"
          onClick={() => history.push("/promociones")}
        >
          MIS PROMOCIONES
        </button>
      </div>
    </div>
  );

  return (
    <div className="wallet-container">
      {paymentMessage && (
        <p
          className={
            paymentMessage.includes("exitoso")
              ? "success-message"
              : "failure-message"
          }
        >
          {paymentMessage}
        </p>
      )}
      {desktopWallet()}
      {mobileWallet()}
      <small className="explain-reserved">
        Reservados: Puntos que están en proceso de ser usados en una compra en
        Liquidos.cl
      </small>
    </div>
  );
}

export default WalletInfo;
