import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SplashScreen from "../Loading/SplashScreen";
import { setUserData, logout } from "../../actions/accountActions";
import AuthService from "../../services/authService";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      AuthService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });

      AuthService.handleAuthentication();

      if (AuthService.isAuthenticated()) {
        const user = await AuthService.loginInWithToken();
        await dispatch(setUserData(user));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default Auth;
