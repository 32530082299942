import React, { useState } from "react";
import axios from 'axios';
import Page from "../../components/Layout/Page";
import "./RecoverPassword.css";

const { validate, clean } = require("rut.js");

const RecoverPassword = () => {
  const [rut, setRut] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const submitForm = async () => {
    setError("");
    try {
      setLoading(true);
      // Clean Rut
      if (!validate(rut)) {
        setError("El RUT ingresado no es válido");
        setLoading(false);
        return;
      }

      // Recover password
      const response = await axios.post('/api/recover-password', {
        rut: clean(rut)
      });

      if (response.status !== 200) {
        setError("Ha ocurrido un error. Por favor intente nuevamente más tarde.");
        setLoading(false);
        return;
      }

      const { data } = response;

      if (data.code !== 200) {
        const { errorMessage } = data.data;
        setError(errorMessage);
        setLoading(false);
        return;
      }

      setSent(true);
      setLoading(false);
    } catch (e) {
      console.log('Error: ', e);
      setError("Ha ocurrido un error. Por favor intente nuevamente más tarde.");
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      submitForm();
    }
  };

  return (
    <Page title="Club Líquidos | Recuperar Contraseña" current="recover-password">
      <div className="password-container">
        {sent ? (
          <div>
            <div className="email-sent">
              <img src="/img/green_check.png" alt="Correo enviado" />
              <h2>Te hemos enviado un correo con instrucciones para recuperar tu contraseña</h2>
            </div>
          </div>
        ) : (
          <div className="password-box">
          <h1>Recupera tu Contraseña</h1>
          <div className="password-form">
            <input
              type="text"
              name="rut"
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              placeholder="RUT"
              onKeyDown={handleKeyDown}
            />
          </div>
          <p className="error-message">{error}</p>
          <button
            className="password-button"
            type="button"
            onClick={() => submitForm()}
            disabled={loading}
          >
            Enviar
          </button>
          <p className="password-link">
            <a href="/ingresa">Volver</a>
          </p>
        </div>
        )}
      </div>
    </Page>
  );
};

export default RecoverPassword;
