import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { clearKhipuId, saveKhipuId } from "../../actions/paymentActions";
import axios from "../../utils/axios";
import thousandSeparator from "../../utils/thousandSeparator";
import "./Payment.css";

function Payment({ customer, setPay, setPaymentMessage }) {
  const dispatch = useDispatch();
  const [webPayPlusToken, setWebPayPlusToken] = useState(null);
  const [webPayPlusUrl, setWebPayPlusUrl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [paymentOption, setPaymentOption] = useState("khipu");
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(50);
  const [loading, setLoading] = useState(false);
  const [loadingKhipu, setLoadingKhipu] = useState(false);
  const [minAbono, setMinAbono] = useState(50);

  const { khipu_payment_id } = useSelector((state) => state.payment);
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    const getMinAbono = async () => {
      const settingResponse = await axios.get(`/api/settings/min-abono`);
      if (settingResponse.data.code === 200) {
        const value = settingResponse.data.data.setting.value
        const validValue = parseInt(value)
        if (value && validValue) {
          setMinAbono(validValue)
          setAmount(validValue)
        }
      }
    }
    getMinAbono()
  }, [setPay])
  useEffect(() => {
    const checkPayment = async () => {
      let confirmed = false;
      const maxTries = 10;
      let tries = 0;
      while (!confirmed && tries < maxTries) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const getPaymentUrl = `/api/landing-payments/khipu/${khipu_payment_id}`;
        const khipuResponse = await axios.get(getPaymentUrl);
        if (khipuResponse.data.code === 200) {
          if (khipuResponse.data.data.payment.status === "APROBADO") {
            const amountPayed = khipuResponse.data.data.payment.amount
            confirmed = true;
            dispatch(clearKhipuId());
            setLoadingKhipu(false);
            setPay(false);
            setPaymentMessage(
              `Abono exitoso por $${thousandSeparator(amountPayed, ".")} CLP`
            );
          }
        }
        tries += 1;
      }
    };

    if (khipu_payment_id) {
      const match = matchPath(window.location.pathname, {
        path: "/billetera/:state&:khipu",
        exact: true,
        strict: false,
      });

      if (
        match.params.khipu === "cargando" &&
        match.params.state === "abonar"
      ) {
        setLoadingKhipu(true);
        checkPayment();
      }
    }

  }, [khipu_payment_id, setPay, setPaymentMessage, amount, dispatch]);

  useEffect(() => {
    if (webPayPlusToken && webPayPlusUrl) {
      if (paymentOption === "webpay-plus") {
        document.getElementById("webpay-plus-submit").submit();
      } else if (paymentOption === "webpay-oneclick") {
        document.getElementById("oneclick-submit").submit();
      }
    }
  }, [webPayPlusToken, webPayPlusUrl, paymentOption]);

  const webPayPlusForm = () => {
    return (
      <form id="webpay-plus-submit" action={webPayPlusUrl}>
        <input type="hidden" name="token_ws" value={webPayPlusToken} />
      </form>
    );
  };

  const webPayForm = () => {
    return (
      <form id="oneclick-submit" action={webPayPlusUrl}>
        <input type="hidden" name="TBK_TOKEN" value={webPayPlusToken} />
      </form>
    );
  };

  const addCard = async () => {
    const response = await axios.post("/api/landing-payments/one-click");

    if (response.data.code === 200) {
      setWebPayPlusToken(response.data.data.token);
      setWebPayPlusUrl(response.data.data.url);
    }
  };

  const payWebPayPlus = async () => {
    setLoading(true);
    setWebPayPlusUrl(null);

    const newOrderResponse = await axios.post("/api/webpay-plus/init", {
      amount,
    });
    const paymentData = newOrderResponse.data.data;
    if (paymentData.success) {
      setWebPayPlusUrl(paymentData.tbk_url);
      setWebPayPlusToken(paymentData.token);
      // document.getElementById("form-submit").submit();
      // await new Promise((resolve) => setTimeout(resolve, 20000));
      setLoading(false);
    }
  };

  const payNow = async () => {
    setLoading(true);
    const regex = /^[0-9]*$/
    if (!regex.test(amount)) {
      setError("Debe ingresar un monto correcto");
      setLoading(false);
      return;
    }
    if (!selectedCard && paymentOption === "webpay-oneclick") {
      setError("Debe seleccionar tarjeta");
      setLoading(false);
      return;
    }
    if (amount < minAbono) {
      setError(`Monto mínimo es de ${minAbono} CLP`);
      setLoading(false);
      return;
    }
    if (!paymentOption) {
      setError("Debe seleccionar medio de pago");
      setLoading(false);
      return;
    }

    if (paymentOption === "khipu") {
      setLoadingKhipu(true);
      const khipuResponse = await axios.post(
        "/api/landing-payments/khipu/new",
        {
          amount,
          customerId: user.id,
        }
      );

      if (khipuResponse.data.code === 200) {
        dispatch(saveKhipuId(khipuResponse.data.data.paymentData.payment_id));
        window.location.href =
          khipuResponse.data.data.paymentData.simplified_transfer_url;
      } else {
        setError("Hubo un error, intente nuevamente");
      }
    }

    if (paymentOption === "webpay-oneclick") {
      const payResponse = await axios.post("/api/webpay-oneclick/pay", {
        amount,
        selectedCard,
      });

      setPay(false);
      if (payResponse.data.code === 200) {
        setPaymentMessage(
          `Abono exitoso por $${thousandSeparator(amount, ".")} CLP`
        );
      } else {
        setPaymentMessage("Abono no pudo ser completado, intente de nuevo");
      }
    } else if (paymentOption === "webpay-plus") {
      payWebPayPlus();
    }

    setLoading(false);
  };

  const cardOptions = () => {
    return (
      <select
        value={selectedCard}
        onChange={(e) => setSelectedCard(e.target.value)}
      >
        <option value={0}>Seleccione tarjeta...</option>
        {customer.CreditCards.map((c) => (
          <option value={c.id}>{c.card_number}</option>
        ))}
      </select>
    );
  };

  return (
    <div className="wallet-payment-container">
      {webPayForm()}
      {webPayPlusForm()}
      {!loadingKhipu ? (
        !loading ? (
          <div className="wallet-wrapper">
            <div className="wallet-amount-container">
              <h2>Cantidad a abonar</h2>
              <input
                type="number"
                min={minAbono}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            <div className="select-payment">
              <h2>Seleccione medio de pago</h2>
              <div
                className="payment-method-option"
                onClick={() => setPaymentOption("khipu")}
              >
                <input type="radio" checked={paymentOption === "khipu"} />
                <img src="/img/khipu.png" alt="khipu" />
              </div>
              {/*
              <div
                className="payment-method-option"
                onClick={() => setPaymentOption("match")}
              >
                <input type="radio" checked={paymentOption === "match"} />
                <img src="/img/match.png" alt="match" />
              </div>
              */}
              {/* <div
                className="payment-method-option"
                onClick={() => setPaymentOption("webpay-oneclick")}
              >
                <input
                  type="radio"
                  checked={paymentOption === "webpay-oneclick"}
                />
                <img src="/img/webpay-oneclick.png" alt="oneclick" />
              </div>
              <div
                className="payment-method-option"
                onClick={() => setPaymentOption("webpay-plus")}
              >
                <input type="radio" checked={paymentOption === "webpay-plus"} />
                <img src="/img/webpay-plus.png" alt="webpayplus" />
              </div> */}
            </div>
            {paymentOption === "webpay-oneclick" ? (
              <div className="choose-card-wallet">
                <h2>Seleccione tarjeta</h2>
                {customer.CreditCards && customer.CreditCards.length > 0 ? (
                  cardOptions()
                ) : (
                  <p className="error-message">No tiene tarjetas inscritas</p>
                )}
              </div>
            ) : null}
            {paymentOption === "webpay-oneclick" && (
              <div className="oneclick-button">
                <button type="button" onClick={addCard}>
                  <h2>INSCRIBIR NUEVA TARJETA</h2>
                </button>
              </div>
            )}
            <p className="error-message">{error}</p>
            <button
              disabled={loading}
              className="yellow-button"
              type="button"
              onClick={payNow}
            >
              ABONAR
              </button>
            <button
              className="back-button"
              type="button"
              onClick={() => setPay(false)}
            >
              VOLVER
                </button>


          </div>
        ) : (
          <CircularProgress style={{ color: "var(--yellow" }} />
        )
      ) : (
        <div className="khipu-waiting-confirmation">
          <CircularProgress style={{ color: "var(--yellow" }} />
          <h2>Esperando confirmación de Khipu</h2>
        </div>
      )}
    </div>
  );
}

export default Payment;
