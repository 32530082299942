import AuthService from "../services/authService";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const UPDATE_PROFILE = "@account/update-profile";

export function login(rut, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await AuthService.loginWithRutAndPassword(rut, password);
      if (!user.subscription) {
        dispatch({ type: LOGIN_FAILURE });
        throw new Error("Usuario no suscrito");
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user,
          },
        });
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

export function logout() {
  return async (dispatch) => {
    AuthService.logout();

    dispatch({
      type: LOGOUT,
    });
  };
}

export function register() {
  return true;
}
