import React, { forwardRef, useState } from 'react';
import Navbar from './Navbar/Navbar';
import { Helmet } from 'react-helmet-async';
import SideDrawer from '../SideDrawer/SideDrawer';
import BackDrop from './Backdrop/BackDrop';
// import Footer from './Footer/Footer';

const Page = forwardRef(({
  title,
  children,
  current,
}, ref) => {
  const [open, setOpen] = useState(false)

  let backdrop = <div></div>
  if (open) {
    backdrop = <BackDrop handleClick={() => setOpen(false)} />
  }

  return (
    <div
      ref={ref}
      className="page-container"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {backdrop}
      <Navbar current={current} setOpen={setOpen} open={open} />
      <SideDrawer show={open} />
      <div className="page-sub-container">
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  );
});

export default Page;