/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from "immer";
import { SAVE_KHIPU_ID, CLEAR_KHIPU_ID } from "../actions/paymentActions";

const initialState = {
  khipu_payment_id: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_KHIPU_ID: {
      const { id } = action.payload;
      return produce(state, (draft) => {
        draft.khipu_payment_id = id;
      });
    }

    case CLEAR_KHIPU_ID: {
      return produce(state, (draft) => {
        draft.khipu_payment_id = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default paymentReducer;
