import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../actions/accountActions";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import axios from "../../utils/axios";
import { validate, clean } from "rut.js";
import "react-phone-number-input/style.css";
import Page from "../../components/Layout/Page";
import BirthdayPicker from "../../components/BirthdayPicker";
import { validateEmail } from "../../utils/validator";
import "./Register.css";

function diffYears(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(Math.round(diff / 365.25));
}

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    name: "",
    lastName: "",
    rut: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    birthDate: new Date()
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [waitConfirmation, setWaitConfirmation] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [codeId, setCodeId] = useState(null);
  const [coolDown, setCoolDown] = useState(0);
  const [gender, setGender] = useState('female');

  useEffect(() => {
    const decreaseCoolDown = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (coolDown > 0) {
        setCoolDown(coolDown - 1);
      }
    };

    decreaseCoolDown();
  }, [coolDown, setCoolDown]);

  const handleChange = (event) => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => {
    setInputValues({ ...inputValues, birthDate: date });
  };

  const getInputName = (key) => {
    switch (key) {
      case "name":
        return "Nombre";
      case "lastName":
        return "Apellido";
      case "rut":
        return "RUT";
      case "email":
        return "Email";
      case "password":
        return "Contraseña";
      case "passwordConfirmation":
        return "Confirmar Contraseña";
      case "phone":
        return "Teléfono";
      case "birthDate":
        return "Fecha de nacimiento";
      default:
        return "requerido";
    }
  };

  const sendCode = async () => {
    setLoading(true);
    setCoolDown(60);
    const axiosResponse = await axios.post("/api/customers/register-sms", {
      phone: inputValues.phone,
    });
    if (axiosResponse.data.code !== 200) {
      setError(axiosResponse.data.data.errorMessage);
      setLoading(false);
      return;
    } else {
      setCodeId(axiosResponse.data.data.codeId);
      setLoading(false);
    }
  };

  const submitForm = async () => {
    setError(null);
    setLoading(true);
    for (const key in inputValues) {
      if (!inputValues[key]) {
        const inputName = getInputName(key);
        setError(`Falta llenar campo "${inputName}"`);
        setLoading(false);
        return;
      }
    }

    if (!validate(inputValues.rut)) {
      setError("Debe ingresar un RUT válido");
      setLoading(false);
      return;
    }

    if (!validateEmail(inputValues.email)) {
      setError("Debe ingresar un email válido");
      setLoading(false);
      return;
    }

    if (!isValidPhoneNumber(inputValues.phone)) {
      setError("Debe ingresar un teléfono válido");
      setLoading(false);
      return;
    }

    if (diffYears(new Date(), inputValues.birthDate) < 18) {
      setError("Debe ser mayor de 18 años");
      setLoading(false);
      return;
    }

    if (inputValues.password !== inputValues.passwordConfirmation) {
      setError("Contraseñas no coinciden");
      setLoading(false);
      return;
    }

    if (inputValues.password.length < 8) {
      setError("Contraseña debe tener largo de al menos 8");
      setLoading(false);
      return;
    }

    if (!termsAccepted) {
      setError("Debe aceptar los Términos y Condiciones");
      setLoading(false);
      return;
    }

    // Clean rut
    setInputValues({ ...inputValues, rut: clean(inputValues.rut) });
    sendCode();
    setLoading(false);
    setWaitConfirmation(true);
  };

  const handleRegisterCode = async () => {
    setLoading(true);
    let data = {};
    Object.assign(data, inputValues);
    data.confirmationCode = confirmationCode;
    data.codeId = codeId;
    data.gender = gender;
    const axiosResponse = await axios.post("/api/customers/register", data);
    if (axiosResponse.data.code !== 200) {
      setError(axiosResponse.data.data.errorMessage);
      setLoading(false);
      return;
    } else {
      await dispatch(login(inputValues.rut, inputValues.password));
      setRegistered(true);
      setLoading(false);
    }
  };

  const handleConfirmKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRegisterCode();
    }
  };
  
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  }

  const confirmationBox = () => (
    <div className="confirmation-box">
      <div>
        <h3 className="text-new-code">
          Te enviamos un sms con el código al teléfono que ingresaste!
        </h3>
        <input
          type="text"
          name="confirmationCode"
          value={inputValues.confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          onKeyDown={handleConfirmKeyDown}
        />
        <button
          type="button"
          className="confirm-code"
          onClick={handleRegisterCode}
          disabled={loading}
        >
          Confirmar
        </button>
        <button
          disabled={coolDown > 0}
          type="button"
          className={`send-new-code ${coolDown > 0 && "disabled-code"}`}
          onClick={sendCode}
        >
          Enviar código de nuevo
        </button>
        <p className="edit-cooldown-message">
          {coolDown > 0 && `Podrá enviar otro código en ${coolDown}`}
        </p>
        <p className="error-message">{error}</p>
      </div>
    </div>
  );

  const registerConfirmation = () => {
    history.push("/promociones")
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      submitForm();
    }
  };

  return (
    <Page title="Club Líquidos | Regístrate" current="register">
      {!waitConfirmation ? (
        <div className="register-container">
          <div className="register-box">
            <h1>Registro Club Líquidos</h1>
            <div className="register-form">
              <input
                type="text"
                name="name"
                value={inputValues.name}
                onChange={(e) => handleChange(e)}
                placeholder="Nombre"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                name="lastName"
                value={inputValues.lastName}
                onChange={(e) => handleChange(e)}
                placeholder="Apellido"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                name="rut"
                value={inputValues.rut}
                onChange={(e) => handleChange(e)}
                placeholder="RUT"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                name="email"
                value={inputValues.email}
                onChange={(e) => handleChange(e)}
                placeholder="Email"
                onKeyDown={handleKeyDown}
              />
              <PhoneInput
                defaultCountry="CL"
                placeholder="Teléfono móvil"
                value={inputValues.phone}
                onChange={(e) => setInputValues({ ...inputValues, phone: e })}
              />
              <div className="date-input">
                <BirthdayPicker
                  value={inputValues.birthDate}
                  onChange={(date) => handleDateChange(date)}
                />
                <p>Fecha de nacimiento</p>
              </div>
              <input
                type="password"
                name="password"
                value={inputValues.password}
                onChange={(e) => handleChange(e)}
                placeholder="Contraseña (min. 8 carateres)"
                onKeyDown={handleKeyDown}
              />
              <input
                type="password"
                name="passwordConfirmation"
                value={inputValues.passwordConfirmation}
                onChange={(e) => handleChange(e)}
                placeholder="Confirmar Contraseña"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="gender">
              <label>Género</label><br />
              <input type="radio" name="gender" value="female" checked={gender === 'female'} onChange={handleGenderChange} /><label>Femenino</label><br />
              <input type="radio" name="gender" value="male" checked={gender === 'male'} onChange={handleGenderChange} /><label>Masculino</label><br />
              <input type="radio" name="gender" value="other" checked={gender === 'other'} onChange={handleGenderChange} /><label>Prefiero no decir</label><br />
            </div>
            <div className="terms">
              <input
                id="terms"
                type="checkbox"
                name="terms"
                value="accepted"
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label for="terms">Acepto los <a href="https://storage.googleapis.com/club-liquidos-public/terminos-y-condiciones-club-liquidos.pdf" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></label>
            </div>
            <p className="error-message">{error}</p>
            <button
              className="register-button"
              type="button"
              onClick={() => submitForm()}
              disabled={loading}
            >
              Registrarme
            </button>
          </div>
        </div>
      ) : registered ? (
        registerConfirmation()
      ) : (
        confirmationBox()
      )}
    </Page>
  );
};

export default Register;
