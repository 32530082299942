import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./Navbar.css";
import DrawerToggleButton from "../../SideDrawer/DrawerToggleButton";
import { logout } from "../../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";

const Navbar = ({ current, open, setOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);

  const logoutClick = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <nav>
      <div className="navbar">
        <div className="notification-navbar-message">
          <h2>¡Únete al</h2>
          <img className="black-clubliquidos-img" src="/img/clubliquidos-negro.png" alt="logo" />
          <h2>! Es totalmente gratis</h2>
        </div>
        <div className="navbar-container">
          <div className="burger">
            <DrawerToggleButton handleClick={() => setOpen(!open)} />
          </div>
          <button
            type="button"
            className="navbar-title"
            onClick={() => history.push("/")}
          >
            <img src="https://storage.googleapis.com/club-liquidos-public/logo-club-liquidos.png" alt="logo" />
          </button>
          <div className="navbar-items-container">
            <div
              className={
                current === "more" ? "navbar-item highlight" : "navbar-item"
              }
            >
              <Link to="/saber-mas">QUIERO SABER MÁS</Link>
            </div>
            {/* <div
              className={
                current === "promotions" ? "navbar-item highlight" : "navbar-item"
              }
            >
              <Link to="/promociones">PROMOCIONES</Link>
            </div> */}
            <div
              className={
                current === "profile" ? "navbar-item highlight" : "navbar-item"
              }
            >
              <Link to="/perfil">PERFIL</Link>
            </div>
            <div
              className={
                current === "wallet" ? "navbar-item highlight" : "navbar-item"
              }
            >
              <Link to="/billetera/info&simple">BILLETERA</Link>
            </div>
            <div
              className={
                current === "volver" ? "navbar-item highlight" : "navbar-item"
              }
            >
              <a href="https://www.liquidos.cl/">
                VOLVER A <img src="/img/logo-liquidos-blanco.png" alt="logo" />
              </a>
            </div>
            <div className="navbar-item-login">
              {!user ? (
                <Link to="/ingresa">LOGIN</Link>
              ) : (
                <button
                  className="navbar-item-login"
                  type="button"
                  onClick={logoutClick}
                >
                  LOGOUT
                </button>
              )}
            </div>
            {/* <div className="burger">
              <DrawerToggleButton handleClick={() => setOpen(!open)} />
            </div> */}
          </div>
        </div>
      </div>
      <div className="navbar-container-border" />
    </nav>
  );
};

export default Navbar;
