import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./SideDrawer.css";

const SideDrawer = (props) => {
  let className = "side_drawer";
  if (props.show) {
    className = "side_drawer open";
  }
  return (
    <nav className={className}>
      <ul>
        <li>
          <Link to="/saber-mas">QUIERO SABER MÁS</Link>
        </li>
        <li>
          <Link to="/promociones">PROMOCIONES</Link>
        </li>
        <li>
          <Link to="/perfil">PERFIL</Link>
        </li>
        <li>
          <Link to="/billetera/info&simple">BILLETERA</Link>
        </li>
        <li>
          <a className="back-to-liquidos" href="https://www.liquidos.cl/">
            LIQUIDOS.CL
          </a>
        </li>
        <li>
          <Link to="/ingresa">LOGIN</Link>
        </li>
      </ul>
    </nav>
  );
};

SideDrawer.propTypes = {
  show: PropTypes.any,
  onCotizaClick: PropTypes.func,
};

export default SideDrawer;
