import React, { useState } from "react";
import ReactPlayer from "react-player";
import Popover from "@material-ui/core/Popover";
import { Check } from "react-feather";
import Page from "../../components/Layout/Page";
import "./More.css";

const More = () => {
  const [open, setOpen] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const openYoutube = (link) => {
    setOpen(true);
    setVideoLink(link);
  };

  return (
    <Page title="Club Líquidos" current="more">
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div>
          <ReactPlayer url={videoLink} className="youtube-popover" />
        </div>
      </Popover>
      <div className="more">
        <div className="more-partner">
          <img
            src="/img/youtube.png"
            alt="youtube"
            onClick={() => openYoutube("https://youtu.be/yzMKFtfM5UQ")}
          />
          <h1>¿Qué es ser Socio?</h1>
          <div className="checkbox-item">
            <Check />
            <p>Es completamente Gratis</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>Más descuentos</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>Acumula LiquiPuntos</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>Los LiquiPuntos son plata</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>* Sin letra chica</p>
          </div>
          <div className="checkbox-item">
            <button
              type="button"
              className="yellow-button register-now"
              onClick={() => {
                window.location.href = "/registrate";
              }}
            >
              Registrate Aquí
            </button>
          </div>
        </div>
        <div className="more-fan-partner">
          <img
            className="coming-soon"
            src="/img/proximamente.png"
            alt="proximamente"
          />
          <img
            src="/img/youtube.png"
            alt="youtube"
            onClick={() => openYoutube("https://youtu.be/clCQ8S5202c")}
          />
          <h1>¿Qué es un Socio Fan?</h1>
          <div className="checkbox-item">
            <Check />
            <p>Es completamente Gratis</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>Aún más descuentos</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>Aún más LiquiPuntos</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>
              Tu abono es TUYO… úsalo para comprar lo que quieras en Liquidos.cl
            </p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>Participa en sorteos y experiencias exclusivas</p>
          </div>
          <div className="checkbox-item">
            <Check />
            <p>* Sin letra chica</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default More;
