import React from 'react'
import PropTypes from 'prop-types'
import styles from './BackDrop.module.css'

const backdrop = props => (
  <div className={styles.backdrop} onClick={() => props.handleClick()} />
)

backdrop.propTypes = {
  handleClick: PropTypes.func
}

export default backdrop
