import React from "react";
import { useHistory } from "react-router-dom";
import Page from "../../components/Layout/Page";
import "./TbkFinal.css";

function TbkRejectWebPay() {
  const history = useHistory();

  return (
    <Page title="Club Líquidos | TBK Reject">
      <div className="tbk-reject-container">
        <div className="tbk-reject-info">
          <img src="/img/red_cross.png" alt="success" />
          <h1>Abono rechazado</h1>
          <h2>Algo salió mal, intente de nuevo</h2>
          <button
            className="yellow-button"
            type="button"
            onClick={() => history.push("/info")}
          >
            IR A BILLETERA
          </button>
        </div>
      </div>
    </Page>
  );
}

export default TbkRejectWebPay;
