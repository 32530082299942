import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Page from "../../components/Layout/Page";
import thousandSeparator from "../../utils/thousandSeparator";
import "./Promotions.css"
import items from "./items"

function Promotions() {
  const customer = useSelector((state) => state.account.user);
  const history = useHistory();

  useEffect(() => {
    if (!customer) {
      history.push("/ingresa");
      return;
    }
  }, [customer, history]);

  return (
    <Page title="Club Líquidos | Promociones" current="promotions">
      <div className="promotions-banner">
        <img src="https://storage.googleapis.com/club-liquidos-public/GRAFICA_CLUBLIQUIDOS-03.png" alt="Promociones" />
      </div>
      <div className="promotions-banner">
        <a href="https://www.liquidos.cl/categorias/club-liquidos?promotion=club%20liquidos.cl"><img src="https://storage.googleapis.com/club-liquidos-public/LANDING-CLUB-MARZO.jpg" alt="Promociones" /></a>
      </div>
    </Page>
  );
}

export const promotionItems = () => {
  if (items.length > 0) {
    return <div className="promotions-container">
      {items.map((item) => <PromotionItem item={item} />)}
    </div>

  }
  else {
    return (
      <div className="promotions-container-no-items"><p>No hay promociones aún</p></div>
    )
  }
}

const PromotionItem = ({ item }) => {
  return (
    <div className="promotion-item">
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <img src={item.img_url} alt={item.name} className="promotion-item-img" />
        <div className="promotion-item-text">
          <p className="promotion-item-name">{item.name}</p>
          <p className="promotion-item-price">${thousandSeparator(item.price, ".")}</p>
        </div>
      </a>
    </div>
  );
}

export default Promotions;
