import React from 'react'
import PropTypes from 'prop-types'
import './DrawerToggleButton.css'

const DrawerToggleButton = props => (
  <button className="toggle_button" onClick={() => props.handleClick()}>
    <div className="toggle_button_line" />
    <div className="toggle_button_line" />
    <div className="toggle_button_line" />
  </button>
)

DrawerToggleButton.propTypes = {
  handleClick: PropTypes.func
}

export default DrawerToggleButton
