import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountReducer from "./accountReducer";
import paymentReducer from "./paymentReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  payment: paymentReducer,
  form: formReducer,
});

export default rootReducer;
