import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";
import queryString from 'query-string';
import axios from 'axios';
import Page from "../../components/Layout/Page";
import "./ResetPassword.css";

const ResetPassword = () => {
  const location = useLocation();
  const [confirmed, setConfirmed] = useState(false);
  const [rut, setRut] = useState(null);
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const verifyInfo = async (rut, token) => {
      const response = await axios.post('/api/verify-password-reset', {
        rut,
        token
      });

      if (response.status !== 200) {
        window.location.href = '/ingresa';
        return;
      }

      const { data } = response;

      if (!data) {
        window.location.href = '/ingresa';
        return;
      }

      if (!data.success) {
        window.location.href = '/ingresa';
        return;
      }

      if (data.code === 200) {
        setRut(rut);
        setToken(token);
        setConfirmed(true);
      } else {
        window.location.href = '/ingresa';
        return;
      }
    };

    // Get query info
    const query = queryString.parse(location.search);
    const { rut, token } = query;

    // Verify query info
    verifyInfo(rut, token);
  }, [location.search]);

  const submitForm = async () => {
    setError("");
    if (!rut) {
      setError('Ha ocurrido un error');
      return;
    }

    if (!token) {
      setError('Ha ocurrido un error');
      return;
    }

    if (!password) {
      setError('Debe ingresar una nueva contraseña');
      return;
    }

    if (!confirmPassword) {
      setError('Debe confirmar su nueva contraseña');
      return;
    }

    if (password.length < 8 || confirmPassword.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
      return;
    }

    if (password !== confirmPassword) {
      setError('Las contraseñas deben coincidir');
      return;
    }

    setLoading(true);

    // Reset password
    const response = await axios.post('/api/reset-password', {
      rut,
      token,
      password,
      confirmPassword,
    });

    setLoading(false);

    const { data } = response;
    if (!data) {
      setError('Ha ocurrido un error');
      return;
    }

    if (data.success) {
      setFinished(true);
      return;
    } else {
      setError('Ha ocurrido un error');
      return;
    }
  };

  return (
    <Page title="Club Líquidos | Restablecer Contraseña" current="reset-password">
      <div className="password-container">
        {!confirmed || loading ? (
          <div>
            <CircularProgress style={{ color: "var(--yellow" }} />
          </div>
        ) : (
          <div>
            {finished ? (
              <div className="email-sent">
                <img src="/img/green_check.png" alt="Correo enviado" />
                <h2>¡Tu contraseña ha sido actualizada!</h2>
                <p className="password-link">
                  <a href="/ingresa">Iniciar Sesión</a>
                </p>
              </div>
            ) : (
              <div className="password-box">
                <h1>Restablece tu Contraseña</h1>
                <div className="password-form">
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Contraseña nueva"
                  />
                  <input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirma tu contraseña"
                  />
                </div>
                <p className="error-message">{error}</p>
                <button
                  className="password-button"
                  type="button"
                  onClick={() => submitForm()}
                  disabled={loading}
                >
                  Enviar
                </button>
              </div>  
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default ResetPassword;
