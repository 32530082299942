import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Popover from "@material-ui/core/Popover";
import "./EditAddresses.css";
import { useSelector } from "react-redux";
import AddAddress from "./Address/AddAddress";
import EditAddress from "./Address/EditAddress";

function EditAddresses({ setEditAddresses }) {
  const customer = useSelector((state) => state.account.user);
  const [addAddress, setAddAddress] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [address, setAddress] = useState(null);
  const [addresses, setAddresses] = useState(customer.Addresses);
  const [coolDown, setCoolDown] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const decreaseCoolDown = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (coolDown > 0) {
        setCoolDown(coolDown - 1);
      }
    };

    decreaseCoolDown();
  }, [coolDown, setCoolDown]);

  useEffect(() => {
    const getCustomerInfo = async () => {
      if (customer) {
        const customerResponse = await axios.get(
          `/api/customers/${customer.id}`
        );

        if (customerResponse.data.code === 200) {
          setAddresses(customerResponse.data.data.customer.Addresses);
        }
      }
    };

    getCustomerInfo();
  }, [customer, editAddress, addAddress, deleteAddress]);

  const addressesOptions = () => {
    if (addresses.length > 0) {
      return (
        <ul>
          {addresses
            .sort((a, b) => {
              return b.id - a.id;
            })
            .map((address) => (
              <li>
                <p>{`${address.street}, ${address.commune},  ${address.region} `}</p>
                <button
                  className="edit-address-button"
                  onClick={() => {
                    setEditAddress(true);
                    setAddress(address);
                  }}
                >
                  Editar
                </button>
                <button
                  className="delete-address-button"
                  onClick={() => {
                    setDeleteAddress(true);
                    setAddress(address);
                  }}
                >
                  Eliminar
                </button>
              </li>
            ))}
        </ul>
      );
    } else {
      return (
        <div className="addresses-msg">
          <p>Aún no hay direcciones</p>
        </div>
      );
    }
  };

  const handleDeleteAddress = async () => {
    setLoading(true);
    const axiosResponse = await axios.delete(
      `/api/customers/${customer.id}/delete-address/${address.id}`
    );

    if (axiosResponse.data.code !== 200) {
      setError(axiosResponse.data.data.errorMessage);
      setLoading(false);
    } else {
      setDeleteAddress(false);
      setLoading(false);
    }
  };

  const displayDeleteConfirmationPopover = () => {
    return (
      <Popover
        open={deleteAddress}
        onClose={() => deleteAddress(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div className="popover-delete-address-content">
          <h2>¿Seguro que desea eliminar esta dirección?</h2>
          <p>
            {address.street}, {address.commune}, {address.region}
          </p>

          <div className="confirm-buttons">
            <button
              className="confirm-cancel"
              type="button"
              disabled={loading}
              onClick={handleDeleteAddress}
            >
              Sí
            </button>
            <button
              className="stop-cancel"
              type="button"
              onClick={() => setDeleteAddress(false)}
            >
              No
            </button>
          </div>
        </div>
      </Popover>
    );
  };

  const renderComponent = () => {
    if (addAddress) {
      return <AddAddress setAddAddress={setAddAddress} />;
    } else if (editAddress) {
      return <EditAddress setEditAddress={setEditAddress} address={address} />;
    } else {
      return (
        <div className="edit-addresses-container">
          <div className="edit-addresses-box">
            <h1>Editar direcciones</h1>
            {deleteAddress ? displayDeleteConfirmationPopover() : null}
            {addressesOptions()}
            <button
              className="edit-addresses-button"
              type="button"
              onClick={() => setAddAddress(true)}
            >
              Agregar +
            </button>
            <p className="error-message">{error}</p>
            <button
              className="back-profile-button"
              type="button"
              onClick={() => setEditAddresses(false)}
            >
              Volver a Perfil
            </button>
          </div>
        </div>
      );
    }
  };
  return <div>{renderComponent()}</div>;
}

export default EditAddresses;
