import React, { useEffect, useState } from "react";
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../components/Layout/Page";
import FansPage from "./FansPage";
import EditInfo from "./EditInfo";
import EditPassword from "./EditPassword";
import EditAddresses from "./EditAddresses";
import { format } from "rut.js";

import "./Profile.css";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { logout } from "../../actions/accountActions";

function Profile() {
  const customer = useSelector((state) => state.account.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openFans, setOpenFans] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editAddresses, setEditAddresses] = useState(false);
  const [updatedCustomer, setUpdatedCustomer] = useState({
    subscription: true,
  });
  const [error, setError] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    const getCustomerInfo = async () => {
      if (customer) {
        const customerResponse = await axios.get(
          `/api/customers/${customer.id}`
        );

        if (customerResponse.data.code === 200) {
          setUpdatedCustomer(customerResponse.data.data.customer);
        } else {
          dispatch(logout());
        }
      } else {
        dispatch(logout());
      }
    };

    getCustomerInfo();
  }, [customer, history, dispatch]);

  const profileInfoRow = (name, info) => {
    let newInfo = info;
    if (name === "Fecha de Nacimiento") {
      newInfo = moment(info).format("DD/MM/YYYY");
    }
    return (
      <div className="profile-info-row">
        <h2>{name}</h2>
        <p>{newInfo}</p>
      </div>
    );
  };

  const displayProfileInfo = () => {
    let fullName = "";
    if (updatedCustomer.name && updatedCustomer.lastName) {
      fullName = `${updatedCustomer.name} ${updatedCustomer.lastName}`;
    }
    return (
      <div className="profile-info">
        {profileInfoRow("Nombre Completo", fullName)}
        {profileInfoRow("RUT", format(updatedCustomer.rut))}
        {profileInfoRow("Email", updatedCustomer.email)}
        {profileInfoRow("Teléfono Móvil", updatedCustomer.phone_number)}
        {profileInfoRow("Fecha de Nacimiento", updatedCustomer.birth_date)}
      </div>
    );
  };

  const changeSubscription = async () => {
    setError(null);
    const subsResponse = await axios.put(
      `/api/customers/${customer.id}/change-subscription`
    );

    if (subsResponse.data.code === 200) {
      setUpdatedCustomer(subsResponse.data.data.customer);
      if (openConfirmation) {
        setOpenConfirmation(false);
      }
      if (
        subsResponse.data.data.customer &&
        !subsResponse.data.data.customer.subscription
      ) {
        dispatch(logout());
      }
    } else {
      setError("No pudimos cancelar su suscripción");
    }
  };

  const displaySubscription = () => (
    <div className="normal-subscription-profile">
      <p className="error-message">{error}</p>
      {updatedCustomer.subscription ? (
        <button
          type="button"
          onClick={() => setOpenConfirmation(true)}
          className={"subscription-button cancel-subscription"}
        >
          Cancelar suscripción al Club Líquidos
        </button>
      ) : (
        <button
          type="button"
          onClick={() => changeSubscription()}
          className={"subscription-button activate-subscription"}
        >
          Unir al Club
        </button>
      )}
      {updatedCustomer.subscription ? (
        <p>
          Al cancelar la suscripción perderás
          <br />
          los Liquipuntos que haya acumulado
        </p>
      ) : (
        <p>
          Suscribirse al Club Líquidos no
          <br />
          tiene ningún costo, es 100% gratis
        </p>
      )}
    </div>
  );

  const displayFans = () => (
    <div className="fan-subscription-profile">
      <button
        className="fan-page-button"
        type="button"
        onClick={() => setOpenFans(true)}
      >
        Abrir página de Socio FAN
      </button>
    </div>
  );

  const displayWallet = () => (
    <div className="wallet-profile">
      <button
        className="profile-wallet-button"
        type="button"
        onClick={() => history.push("/billetera/info&simple")}
      >
        <img src="/img/wallet.png" alt="billetera" className="wallet-icon" />
        {" Mi Billetera"}
      </button>
    </div>
  );

  const displayEditInfo = () => (
    <div className="edit-profile first-button">
      <button
        className="edit-info-button"
        type="button"
        onClick={() => setEditInfo(true)}
      >
        Editar información
      </button>
    </div>
  );

  const displayEditPassword = () => (
    <div className="edit-profile">
      <button
        className="edit-info-button"
        type="button"
        onClick={() => setEditPassword(true)}
      >
        Cambiar contraseña
      </button>
    </div>
  );

  const displayEditAddresses = () => (
    <div className="edit-profile">
      <button
        className="edit-info-button"
        type="button"
        onClick={() => setEditAddresses(true)}
      >
        Editar direcciones
      </button>
    </div>
  );

  const displayConfirmationPopover = () => {
    return (
      <Popover
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div className="popover-cancel-content">
          <h2>¿Seguro que desea cancelar la suscripción al Club Líquidos?</h2>
          <p className="sorry-message">
            Que pena que quieras dejar el Club Líquidos
            <br />
            ¡Te esperamos a la vuelta!
          </p>
          <div className="confirm-buttons">
            <button
              className="confirm-cancel"
              type="button"
              onClick={changeSubscription}
            >
              Sí
            </button>
            <button
              className="stop-cancel"
              type="button"
              onClick={() => setOpenConfirmation(false)}
            >
              No
            </button>
          </div>
          <p className="last-warning">
            Recuerda que perderas todos tus Liquipuntos ganados por compras,
            pero tus Liquipuntos por abonos estarán disponibles cuando vuelvas
            al Club
          </p>
        </div>
      </Popover>
    );
  };

  const renderComponent = () => {
    if (editInfo) {
      return <EditInfo setEditInfo={setEditInfo} />;
    } else if (editPassword) {
      return <EditPassword setEditPassword={setEditPassword} />;
    } else if (editAddresses) {
      return <EditAddresses setEditAddresses={setEditAddresses} />;
    } else {
      if (!openFans) {
        return (
          <div className="profile-container">
            {displayConfirmationPopover()}
            {displayProfileInfo()}
            <div className="profile-subscriptions">
              <div className="right-side-profile">
                {displayEditInfo()}
                {displayEditAddresses()}
                {displayEditPassword()}
                {displayFans()}
                {displayWallet()}
              </div>
              {displaySubscription()}
            </div>
          </div>
        );
      } else {
        return <FansPage setOpenFans={setOpenFans} />;
      }
    }
  };

  return (
    <Page title="Club Líquidos | Perfil" current="profile">
      {renderComponent()}
    </Page>
  );
}

export default Profile;
