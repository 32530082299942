import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { matchPath } from "react-router";
import Page from "../../components/Layout/Page";
import Payment from "./Payment";
import WalletInfo from "./WalletInfo";

const Wallet = () => {
  const history = useHistory();
  const customer = useSelector((state) => state.account.user);
  const [pay, setPay] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState(null);

  useEffect(() => {
    if (!customer) {
      history.push("/ingresa");
      return;
    }
    const match = matchPath(window.location.pathname, {
      path: "/billetera/:state&:khipu",
      exact: true,
      strict: false,
    });
    if (match.params.state === "abonar") {
      setPay(true);
    }
  }, [customer, history]);

  return (
    <Page title="Club Líquidos | Billetera" current="wallet">
      {pay && (
        <Payment
          setPay={setPay}
          customer={customer}
          setPaymentMessage={setPaymentMessage}
        />
      )}
      {!pay && (
        <WalletInfo setPay={setPay} paymentMessage={paymentMessage} pay={pay} />
      )}
    </Page>
  );
};

export default Wallet;
