import React from 'react';
import Routes from './Routes';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Auth from './components/Auth/Auth';
import Analytics from 'react-router-ga';
import './App.css';

const history = createBrowserHistory();

const App = () => {
  return (
    <Router history={history}>
      <Analytics id="UA-162896828-1">
        <Auth>
          <Routes />
        </Auth>
      </Analytics>
    </Router>
  );
}

export default App;
