/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import "./AddAddress.css";
import { useSelector } from "react-redux";
import regionsCommunes from "../../../utils/addressInfo";

function EditAddress({ setEditAddress, address }) {
  const customer = useSelector((state) => state.account.user);
  const [inputValues, setInputValues] = useState({
    region: address.region,
    commune: address.commune,
    street: address.street,
    number: address.number,
    apt: address.apt,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [coolDown, setCoolDown] = useState(0);

  useEffect(() => {
    const decreaseCoolDown = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (coolDown > 0) {
        setCoolDown(coolDown - 1);
      }
    };

    decreaseCoolDown();
  }, [coolDown, setCoolDown]);

  const handleChange = (event) => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const getInputName = (key) => {
    switch (key) {
      case "region":
        return "region";
      case "commune":
        return "comuna";
      case "street":
        return "calle";
      case "number":
        return "número";
      default:
        return "requerido";
    }
  };

  const submitForm = async () => {
    setError(null);
    setLoading(true);
    for (const key in inputValues) {
      if (!inputValues[key] && !["apt"].includes(key)) {
        const inputName = getInputName(key);
        setError(`Falta llenar campo "${inputName}"`);
        setLoading(false);
        return;
      }
    }

    let data = {};
    Object.assign(data, inputValues);
    data.customer_id = customer.id;
    const axiosResponse = await axios.put(
      `/api/customers/${customer.id}/edit-address/${address.id}`,
      data
    );

    if (axiosResponse.data.code !== 200) {
      setError(axiosResponse.data.data.errorMessage);
      setLoading(false);
    } else {
      setEditAddress(false);
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      submitForm();
    }
  };

  const regionInputs = () => {
    return Object.keys(regionsCommunes).map((reg, index) => {
      const selected = reg === inputValues.region;
      return (
        <option key={index} value={reg} selected={selected}>
          {reg}
        </option>
      );
    });
  };

  const communeInputs = () => {
    const communes = regionsCommunes[inputValues.region].sort();
    return communes.map((com, index) => {
      const selected = com === inputValues.commune;
      return (
        <option key={index} value={com} selected={selected}>
          {com}
        </option>
      );
    });
  };

  const newAddress = () => {
    return (
      <div className="add-address-container">
        <div className="add-address-box">
          <h1>Editar dirección</h1>
          <div className="add-address-form">
            <select
              name="region"
              value={inputValues.region}
              placeholder="Región"
              onChange={(v) => {
                handleChange(v);
              }}
              onKeyDown={handleKeyDown}
            >
              {regionInputs()}
            </select>
            <select
              name="commune"
              value={inputValues.commune}
              placeholder="Comuna"
              onChange={(v) => {
                handleChange(v);
              }}
              onKeyDown={handleKeyDown}
            >
              {communeInputs()}
            </select>
            <input
              type="text"
              name="street"
              value={inputValues.street}
              onChange={(e) => handleChange(e)}
              placeholder="Calle"
              onKeyDown={handleKeyDown}
            />
            <input
              type="text"
              name="number"
              value={inputValues.number}
              onChange={(e) => handleChange(e)}
              placeholder="Número"
              onKeyDown={handleKeyDown}
            />
            <input
              type="text"
              name="apt"
              value={inputValues.apt}
              onChange={(e) => handleChange(e)}
              placeholder="Casa / Dpto (opcional)"
              onKeyDown={handleKeyDown}
            />
          </div>
          <p className="error-message">{error}</p>
          <button
            className="add-address-button"
            type="button"
            onClick={() => submitForm()}
            disabled={loading}
          >
            Guardar cambios
          </button>
          <button
            className="back-addresses-button"
            type="button"
            onClick={() => setEditAddress(false)}
          >
            Volver a direcciones
          </button>
        </div>
      </div>
    );
  };

  return <div>{newAddress()}</div>;
}

export default EditAddress;
