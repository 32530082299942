import React from "react";
import { useHistory } from "react-router-dom";
import Page from "../../components/Layout/Page";
import "./TbkFinal.css";

function TbkFinal() {
  const history = useHistory();

  return (
    <Page title="Club Líquidos | TBK Final">
      <div className="tbk-final-container">
        <div className="tbk-final-info">
          <img src="/img/green_check.png" alt="success" />
          <h1>Tarjeta agregada con éxito</h1>
          <button
            className="yellow-button"
            type="button"
            onClick={() => history.push("/billetera/abonar&simple")}
          >
            IR A ABONAR
          </button>
        </div>
      </div>
    </Page>
  );
}

export default TbkFinal;
