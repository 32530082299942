import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthGuard from "./components/Auth/AuthGuard";
import Home from "./views/Home/Home";
import More from "./views/More/More";
import Login from "./views/Login/Login";
import Register from "./views/Register/Register";
import Wallet from "./views/Wallet/Wallet";
import TbkFinal from "./views/Payments/TbkFinal";
import TbkReject from "./views/Payments/TbkReject";
import TbkFinalWebPay from "./views/Payments/TbkFinalWebPay";
import TbkRejectWebPay from "./views/Payments/TbkRejectWebPay";
import Promotions from "./views/Promotions/Promotions";
import Profile from "./views/Profile/Profile";
import RecoverPassword from "./views/RecoverPassword/RecoverPassword";
import ResetPassword from "./views/ResetPassword/ResetPassword";

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: "/",
    component: () => <Home />,
  },
  {
    id: 2,
    exact: true,
    path: "/saber-mas",
    component: () => <More />,
  },
  {
    id: 3,
    exact: true,
    path: "/ingresa",
    component: () => <Login />,
  },
  {
    id: 4,
    exact: true,
    path: "/registrate",
    component: () => <Register />,
  },
  {
    id: 5,
    exact: true,
    path: "/billetera/:state&:khipu",
    component: () => <Wallet />,
  },
  {
    id: 6,
    exact: true,
    path: "/tbk-oneclick-return",
    component: () => <TbkFinal />,
  },
  {
    id: 7,
    exact: true,
    path: "/tbk-oneclick-reject",
    component: () => <TbkReject />,
  },
  {
    id: 8,
    exact: true,
    path: "/tbk-webpay-return",
    component: () => <TbkFinalWebPay />,
  },
  {
    id: 9,
    exact: true,
    path: "/tbk-webpay-reject",
    component: () => <TbkRejectWebPay />,
  },
  // {
  //   id: 10,
  //   exact: true,
  //   path: "/promociones",
  //   component: () => <Promotions />,
  // },
  // {
  //   id: 11,
  //   exact: true,
  //   path: "/bienvenido",
  //   component: () => <Welcome />,
  // },
  {
    id: 12,
    exact: true,
    path: "/recuperar-contrasena",
    component: () => <RecoverPassword />,
  },
  {
    id: 13,
    exact: true,
    path: "/restablecer-contrasena",
    component: () => <ResetPassword />,
  },
  {
    id: 50,
    path: "/perfil",
    guard: AuthGuard,
    routes: [
      {
        id: 51,
        exact: true,
        path: "/perfil",
        component: () => <Profile />,
      },
    ],
  },
  {
    id: 100,
    component: () => <Redirect to="/" />,
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
