import React from "react";
import "./FansPage.css";

function FansPage({ setOpenFans }) {
  return (
    <div>
      <h1 style={{  
        textAlign: 'center',
        color: '#fff'
      }}>Próximamente...</h1>
      <div style={{textAlign: 'center'}}>
        <button
          className="yellow-button"
          type="button"
          onClick={() => setOpenFans(false)}
        >Volver</button>
      </div>
    </div>
  );
}

export default FansPage;
