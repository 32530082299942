import jwtDecode from "jwt-decode";
import axios from "../utils/axios";

class AuthService {
  static setAxiosInterceptors({ onLogout }) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthService.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  }

  static handleAuthentication() {
    const accessToken = AuthService.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (AuthService.isValidToken(accessToken)) {
      AuthService.setSession(accessToken);
    } else {
      AuthService.setSession(null);
    }
  }

  static loginWithRutAndPassword(rut, password) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/login", { rut, password })
        .then((response) => {
          if (response.data.data.user) {
            AuthService.setSession(
              response.data.data.accessToken,
              response.data.data.user.id
            );
            resolve(response.data.data.user);
          } else {
            reject(response.data.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static loginInWithToken() {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("userId");
      axios
        .get(`/api/customers/${userId}`)
        .then((response) => {
          if (response.data.data.customer) {
            resolve(response.data.data.customer);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static logout() {
    AuthService.setSession(null);
    axios.post("/api/logout");
  }

  static setSession(accessToken, userId) {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
    if (userId) {
      localStorage.setItem("userId", userId);
    }
  }

  static getAccessToken() {
    return localStorage.getItem("accessToken");
  }

  static isValidToken(accessToken) {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  static isAuthenticated() {
    return !!AuthService.getAccessToken();
  }
}

export default AuthService;
