import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import "./Loading.css";

function SplashScreen() {
  return (
    <div className="loading-page">
      <Box display="flex" justifyContent="center" mb={6}>
        <img className="logo" src="/img/logo-liquidos-blanco.png" alt="logo" />
      </Box>
      <CircularProgress style={{ color: "var(--yellow" }} />
    </div>
  );
}

export default SplashScreen;
