import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../actions/accountActions";
import Page from "../../components/Layout/Page";
import "./Login.css";

const { validate, clean } = require("rut.js");

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rut, setRut] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const submitLogin = async () => {
    setError("");
    try {
      setLoading(true);
      // Clean Rut
      if (!validate(rut)) {
        setError("El RUT ingresado no es válido");
        setLoading(false);
        return;
      }

      if (!verified) {
        setError("CAPTCHA no validado");
        setLoading(false);
        return;
      }

      await dispatch(login(clean(rut), password));
      setLoading(false);
      history.push("/perfil");
    } catch (e) {
      const message =
        e.errorMessage ||
        (e.message === "Usuario no suscrito"
          ? e.message
          : "Ha ocurrido un error. Por favor intente nuevamente más tarde.");
      setError(message);
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      submitLogin();
    }
  };

  const onVerify = (e) => {
    setVerified(true);
  };

  return (
    <Page title="Club Líquidos | Login" current="login">
      <div className="login-container">
        <div className="login-box">
          <h1>Ingresa a Club Líquidos</h1>
          <div className="login-form">
            <input
              type="text"
              name="rut"
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              placeholder="RUT"
              onKeyDown={handleKeyDown}
            />
            <input
              type="password"
              name="rut"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="captcha">
            <ReCAPTCHA
              sitekey="6LdLc7YaAAAAAH8z7sPsqaIuURNBLwHuHF9PGSBI"
              onChange={onVerify}
              size={window.innerWidth >= 768 ? "normal" : "compact"}
            />
          </div>

          <p className="error-message">{error}</p>
          <p className="register-link">
            ¿No eres socio? <a href="/registrate">¡Inscribete Aquí!</a>
          </p>
          <button
            className="login-button"
            type="button"
            onClick={() => submitLogin()}
            disabled={loading}
          >
            Login
          </button>
          <p className="password-link">
            <a href="/recuperar-contrasena">¿Olvidaste tu contraseña?</a>
          </p>
        </div>
      </div>
    </Page>
  );
};

export default Login;
