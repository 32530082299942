import React from "react";
import { useHistory } from "react-router-dom";
import Page from "../../components/Layout/Page";
import "./TbkFinal.css";

function TbkReject() {
  const history = useHistory();

  return (
    <Page title="Club Líquidos | TBK Reject">
      <div className="tbk-reject-container">
        <div className="tbk-reject-info">
          <img src="/img/red_cross.png" alt="success" />
          <h1>Tarjeta No Inscrita</h1>
          <h2>
            Lamentablemente la inscripcion de la tarjeta ha sido rechazada
          </h2>
          <button
            className="yellow-button"
            type="button"
            onClick={() => history.push("/billetera/info&simple")}
          >
            IR A BILLETERA
          </button>
        </div>
      </div>
    </Page>
  );
}

export default TbkReject;
